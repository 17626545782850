<template>
  <div class="web_font_body">
    <div class="topbannerbox">
      <div class="top_banner_box" id="banneritem">
        <div class="top_banener">
          <img class="top_banener_img" src="../../assets/images/calculationPage/logo.png" />
          <div class="top_banener_text">
            <a href="/index">
              <div class="top_banener_text_ac">首页</div>
            </a>
            <a href="#1">
              <div>就业前景</div>
            </a>
            <a href="#2">
              <div>教学体系</div>
            </a>
            <a href="#3">
              <div>授课讲师</div>
            </a>
            <a href="#4">
              <div>就业保障</div>
            </a>
            <a href="#5">
              <div>大学校园</div>
            </a>
          </div>
          <img
            style="width: 23px; height: 24px; margin-right: 10px"
            src="../../assets/images/calculationPage/phone.png"
          />
          <span class="top_banener_num">400-800-2320</span>
        </div>
      </div>
      <div class="flgbox">
        <div class="top_slider_lyb">
          <div class="slider_lyb_input_box">
            <div class="slider_lyb_input">
              <img
                class="slider_lyb_input_img"
                src="../../assets/images/calculationPage/user-name.png"
              />
              <input
                class="slider_lyb_input_text"
                placeholder="请输入您的姓名"
                type="text"
                v-model="inputNameVal"
              />
            </div>
            <div class="slider_lyb_input">
              <img
                class="slider_lyb_input_img"
                src="../../assets/images/calculationPage/user-phone.png"
              />
              <input
                class="slider_lyb_input_text"
                placeholder="请输入您的电话"
                type="text"
                v-model="inputPhoneVal"
              />
            </div>
            <div class="slider_lyb_input_btn" id="lyb_input_btn" @click="handleClickFree()">
              <i class="flash-across"></i>查询附近试学校区
            </div>
          </div>
        </div>
      </div>
      <div class="top_slider swiper-container">
        <div class="swiper-wrapper" @click="advice">
          <div class="swiper-slide">
            <div class="slider-img"></div>
          </div>
          <div class="swiper-slide">
            <div class="slider-img1"></div>
          </div>
          <div class="swiper-slide">
            <div class="slider-img2"></div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="calculationPage_title" id="1">
      <div class="div-img_box">
        <img class="div-img" src="../../assets/images/calculationPage/div.png" />
      </div>
      <div>
        <span>就业路径广-应用场景多 市场需求大</span>
      </div>
      <div class="hov-img_box">
        <img class="hov-img" src="../../assets/images/calculationPage/hov.png" />
      </div>
    </div>
    <div class="cal_line">
      <div class="color_block"></div>
    </div>

    <div class="advantage">
      <div class="advantage_conent">
        <div class="advantage_box">
          <div class="advantage_item" v-if="advantage_num != 1" @mouseenter="hoverItem('1')">
            <div class="item_img_box">
              <img class="item_img" src="../../assets/images/calculationPage/advantage_item01.png" />
            </div>
            <div class="text_box">市场紧缺 需求大</div>
          </div>
          <div class="advantage_item-active" v-if="advantage_num == 1">
            <img class="item-active_img" src="../../assets/images/calculationPage/item-active.png" />
            <div
              class="item_descrition"
            >前端就像“衣服”，任何一款产品想要进入大众视野，都必须要有精美的“外衣”或炫酷的特效，无论大小企业，对前端开发的需求都是越来越大。</div>
            <div class="item_text">市场紧缺 需求大</div>
          </div>
          <div class="advantage_item" v-if="advantage_num != 2" @mouseenter="hoverItem('2')">
            <div class="item_img_box">
              <img class="item_img" src="../../assets/images/calculationPage/advantage_item02.png" />
            </div>
            <div class="text_box">低门槛，简单易学</div>
          </div>
          <div class="advantage_item-active" v-if="advantage_num == 2">
            <img class="item-active_img" src="../../assets/images/calculationPage/item-active.png" />
            <div
              class="item_descrition"
            >WEB前端相对于Java等后端开发语言，不需要太强的逻辑思维能力，入门简单，上手容易，即使无基础，你也可以轻松学会Web前端。</div>
            <div class="item_text">低门槛，简单易学</div>
          </div>
          <div class="advantage_item" v-if="advantage_num != 3" @mouseenter="hoverItem('3')">
            <div class="item_img_box">
              <img class="item_img" src="../../assets/images/calculationPage/advantage_item03.png" />
            </div>
            <div class="text_box">重要性高 待遇好</div>
          </div>
          <div class="advantage_item-active" v-if="advantage_num == 3">
            <img class="item-active_img" src="../../assets/images/calculationPage/item-active.png" />
            <div class="item_descrition">用户体验可以决定一款产品的成败，因此互联网企业对于WEB前端开发越来越重视，薪资自然也是越来越高。</div>
            <div class="item_text">重要性高 待遇好</div>
          </div>
          <div class="advantage_item" v-if="advantage_num != 4" @mouseenter="hoverItem('4')">
            <div class="item_img_box">
              <img class="item_img" src="../../assets/images/calculationPage/advantage_item04.png" />
            </div>
            <div class="text_box">涵盖技术广 发展空间大</div>
          </div>
          <div class="advantage_item-active" v-if="advantage_num == 4">
            <img class="item-active_img" src="../../assets/images/calculationPage/item-active.png" />
            <div class="item_descrition">WEB前端涵盖的知识面非常广，掌握前端开发技能的人有越来越多的就业方向可以选择。</div>
            <div class="item_text">涵盖技术广 发展空间大</div>
          </div>
        </div>
        <div class="icon-img_box">
          <img class="icon-left" src="../../assets/images/calculationPage/icon-left.png" />
          <img class="icon-right" src="../../assets/images/calculationPage/icon-right.png" />
        </div>
        <div class="chart-box">
          <div class="chart-box-item">
            <div class="chart-title yebefore">各大招聘网站前端岗位需求量</div>
            <div class="chart-item-line">
              <div class="chart_line-box">
                <div class="chart_line">
                  <p>22450</p>
                  <img id="lagou" src="../../assets/images/calculationPage/line-lagou.png" />
                  <p>拉勾网</p>
                </div>
                <div class="chart_line">
                  <p>75583</p>
                  <img id="zl" src="../../assets/images/calculationPage/line-zhilian.png" />
                  <p>智联招聘</p>
                </div>
                <div class="chart_line">
                  <p>6340</p>
                  <img id="ly" src="../../assets/images/calculationPage/line-lingying.png" />
                  <p>领英</p>
                </div>
                <div class="chart_line">
                  <p>7359</p>
                  <img id="boss" src="../../assets/images/calculationPage/line-boss.png" />
                  <p>boss</p>
                </div>
                <div class="chart_line">
                  <p>23291</p>
                  <img id="bp" src="../../assets/images/calculationPage/line-baipin.png" />
                  <p>百聘</p>
                </div>
              </div>
            </div>
          </div>
          <div class="chart-box-item">
            <div class="chart-title grbefore">各城市前端开发平均薪资</div>
            <div class="chart-item-cir"></div>
          </div>
        </div>
        <div class="chart_tip">*数据来源于以上招聘网站和职友集 仅供参考</div>
      </div>
      <div class="bgcolor"></div>
    </div>

    <div class="calculationPage_title">
      <div class="div-img_box">
        <img class="div-img" src="../../assets/images/calculationPage/div.png" />
      </div>
      <div>
        <span>拥有好技术 成就好未来</span>
      </div>
      <div class="hov-img_box">
        <img class="hov-img" src="../../assets/images/calculationPage/hov.png" />
      </div>
    </div>
    <div class="cal_line">
      <div class="color_block"></div>
    </div>

    <div class="salary">
      <!-- <div class="salary_box">
				<div class="salary_box_item">
					<img class="salary_medal" src="../../assets/images/calculationPage/salary02.png">
					<div class="salary_year" style="background-color: #4655e8;">2016年</div>
					<div class="salary_text">平均就业薪资</div>
					<div class="salary_money salary_money-img5">
						<span class='salary_money_num'>11059</span>
					</div>
				</div>
				<div class="salary_box_item" style="background-color: #FFFFFF;">
					<img class="salary_medal" src="../../assets/images/calculationPage/salary01.png">
					<div class="salary_year" style="background-color: #717eff;">2017年</div>
					<div class="salary_text" style="color: #000000;">平均就业薪资</div>
					<div class="salary_money salary_money-img6">
						<span class='salary_money_num'>12265</span>
					</div>
				</div>
				<div class="salary_box_item">
					<img class="salary_medal" src="../../assets/images/calculationPage/salary02.png">
					<div class="salary_year" style="background-color: #4655e8;">2018年</div>
					<div class="salary_text">平均就业薪资</div>
					<div class="salary_money salary_money-img5">
						<span class='salary_money_num'>12033</span>
					</div>
				</div>
				<div class="salary_box_item" style="background-color: #FFFFFF;">
					<img class="salary_medal" src="../../assets/images/calculationPage/salary01.png">
					<div class="salary_year" style="background-color: #717eff;">2019年</div>
					<div class="salary_text" style="color: #000000;">平均就业薪资</div>
					<div class="salary_money salary_money-img6">
						<span class='salary_money_num'>12531</span>
					</div>
				</div>
				<div class="salary_box_item">
					<img class="salary_medal" src="../../assets/images/calculationPage/salary03.png">
					<div class="salary_year" style="background-color: #ff9cbb;">2020年</div>
					<div class="salary_text">平均就业薪资</div>
					<div class="salary_money salary_money-img4">
						<span class='salary_money_num'>13189</span>
					</div>
				</div>
				<div class="salary_box_item" style="background-color: #FFFFFF;">
					<img class="salary_medal" src="../../assets/images/calculationPage/salary01.png">
					<div class="salary_year" style="background-color: #717eff;">2021年</div>
					<div class="salary_text" style="color: #000000;">平均就业薪资</div>
					<div class="salary_money salary_money-img6">
						<span class='salary_money_num'>1****</span>
					</div>
				</div>
      </div>-->
      <!-- <div class="salary_box"></div> -->
      <!-- <div class="bgcolor02"></div> -->
    </div>
    <div class="calculationPage_title">
      <div class="div-img_box">
        <img class="div-img" src="../../assets/images/calculationPage/div.png" />
      </div>
      <div>
        <span>掌握前端技术 如APP 小程序 H5游戏等开发技术</span>
      </div>
      <div class="hov-img_box">
        <img class="hov-img" src="../../assets/images/calculationPage/hov.png" />
      </div>
    </div>
    <div class="cal_line">
      <div class="color_block"></div>
    </div>
    <div class="technology">
      <div class="technologybox">
        <div class="technology-icon-left">
          <div class="technology-use" style="top: 30px; left: 150px">
            <span>桌面程序</span>
            <div class="technology-use-imgarea">
              <img
                class="technology-use-img"
                style="top: 0px; left: 0px"
                src="../../assets/images/calculationPage/icon-list.png"
              />
            </div>
          </div>
          <div class="technology-use" style="top: -6px; left: 80px">
            <span>移动WEB</span>
            <div class="technology-use-imgarea">
              <img
                class="technology-use-img"
                style="top: -63px; left: -1px"
                src="../../assets/images/calculationPage/icon-list.png"
              />
            </div>
          </div>
          <div class="technology-use" style="top: -12px; left: 60px">
            <span>移动APP</span>
            <div class="technology-use-imgarea">
              <img
                class="technology-use-img"
                style="top: -30px; left: 0px"
                src="../../assets/images/calculationPage/icon-list.png"
              />
            </div>
          </div>
          <div class="technology-use" style="top: 0px; left: 64px">
            <span>微信小游戏</span>
            <div class="technology-use-imgarea">
              <img
                class="technology-use-img"
                style="top: -125px; left: -1px"
                src="../../assets/images/calculationPage/icon-list.png"
              />
            </div>
          </div>
          <div class="technology-use" style="top: 0px; left: 130px">
            <span>微信视频号</span>
            <div class="technology-use-imgarea">
              <img
                class="technology-use-img"
                style="top: -95px; left: 0px"
                src="../../assets/images/calculationPage/icon-list.png"
              />
            </div>
          </div>
        </div>
        <img class="technologybox-img" src="../../assets/images/calculationPage/cir.png" />
        <div class="technology-text leftTop" style="top: 193px; right: 674px">电商</div>
        <div class="technology-text leftMid" style="top: 312px; right: 720px">在线教育</div>
        <div class="technology-text leftBottom" style="bottom: 200px; right: 670px">社交</div>
        <div class="technology-text rightTop" style="top: 193px; left: 674px">金融</div>
        <div class="technology-text rightMid" style="top: 312px; left: 720px">旅游</div>
        <div class="technology-text rightBottom" style="bottom: 200px; left: 670px">医疗</div>
        <div class="technology-icon-right">
          <div class="technology-use" style="top: 30px; right: 52px">
            <div class="technology-use-imgarea">
              <img
                class="technology-use-img"
                style="top: -30px; left: -36px"
                src="../../assets/images/calculationPage/icon-list.png"
              />
            </div>
            <span>微信公众号</span>
          </div>
          <div class="technology-use" style="top: -4px; right: -2px">
            <div class="technology-use-imgarea">
              <img
                class="technology-use-img"
                style="top: -0px; left: -36px"
                src="../../assets/images/calculationPage/icon-list.png"
              />
            </div>
            <span>微信小程序</span>
          </div>
          <div class="technology-use" style="top: -8px; right: -22px">
            <div class="technology-use-imgarea">
              <img
                class="technology-use-img"
                style="top: -63px; left: -39px"
                src="../../assets/images/calculationPage/icon-list.png"
              />
            </div>
            <span>快应用</span>
          </div>
          <div class="technology-use" style="top: 0px; right: -2px">
            <div class="technology-use-imgarea">
              <img
                class="technology-use-img"
                style="top: -98px; left: -36px"
                src="../../assets/images/calculationPage/icon-list.png"
              />
            </div>
            <span>支付宝小程序</span>
          </div>
          <div class="technology-use" style="top: -6px; right: 40px">
            <div class="technology-use-imgarea">
              <img
                class="technology-use-img"
                style="top: -128px; left: -36px"
                src="../../assets/images/calculationPage/icon-list.png"
              />
            </div>
            <span>服务端</span>
          </div>
        </div>
      </div>
      <div class="technologymid"></div>
      <div class="technologybottom"></div>
      <div class="technologybg"></div>
    </div>
    <div class="calculationPage_title" id="2">
      <div class="div-img_box">
        <img class="div-img" src="../../assets/images/calculationPage/div.png" />
      </div>
      <div>
        <span>前端开发 真正结合企业项目开发需求</span>
      </div>
      <div class="hov-img_box">
        <img class="hov-img" src="../../assets/images/calculationPage/hov.png" />
      </div>
    </div>
    <div class="cal_line">
      <div class="color_block"></div>
    </div>

    <div class="course">
      <div class="course_box">
        <div class="course_nav">
          <div class="course_nav_title">课程大纲</div>
          <div
            :class="[
            'course_nav_item',
            courseid == '1' ? 'course_nav_item_activity' : '',
          ]"
            @mouseenter="hovercourse('1')"
          >第一阶段</div>
          <div
            :class="[
            'course_nav_item',
            courseid == '2' ? 'course_nav_item_activity' : '',
          ]"
            @mouseenter="hovercourse('2')"
          >第二阶段</div>
          <div
            :class="[
            'course_nav_item',
            courseid == '3' ? 'course_nav_item_activity' : '',
          ]"
            @mouseenter="hovercourse('3')"
          >第三阶段</div>
          <div
            :class="[
            'course_nav_item',
            courseid == '4' ? 'course_nav_item_activity' : '',
          ]"
            @mouseenter="hovercourse('4')"
          >第四阶段</div>
          <div
            :class="[
            'course_nav_item',
            courseid == '5' ? 'course_nav_item_activity' : '',
          ]"
            @mouseenter="hovercourse('5')"
          >第五阶段</div>
          <div
            :class="[
            'course_nav_item',
            courseid == '6' ? 'course_nav_item_activity' : '',
          ]"
            @mouseenter="hovercourse('6')"
          >第六阶段</div>
        </div>
        <div class="course_des">
          <div class="course_des_top">
            <img src="../../assets/images/calculationPage/course_top1.png" />
            <img src="../../assets/images/calculationPage/course_top2.png" />
          </div>
          <div class="course_des_mid">
            <div class="course_des_mid_left">主讲内容</div>
            <div class="course_des_mid_right">学习目标</div>
          </div>
          <div class="course_des_content" v-if="courseid == 1">
            <div class="course_des_content_left">
              <p>专业介绍+PS基本操作</p>
              <p>常见开发工具+git安装与简介</p>
              <p>HTML基础</p>
              <p>CSS基础</p>
              <p>盒模型</p>
              <p>浮动相关问题</p>
              <p>PC页面布局</p>
              <p>元素分类及转换</p>
              <p>定位锚点精灵图</p>
              <p>CSS高级</p>
              <p>BFC,iframe</p>
              <p>HTML5新特性</p>
            </div>
            <div class="course_des_content_right">
              <p>熟练掌握前端必备PS技能</p>
              <p>熟练掌握软件开发版本控制工具</p>
              <p>掌握网页常用标签及其属性</p>
              <p>熟练掌握CSS语法，结构，样式，选择器,优先级</p>
              <p>熟练掌握盒模型问题，解决方案</p>
              <p>熟练掌握浮动相关问题问题，浮动方案</p>
              <p>熟练掌握PC开发常见问题</p>
              <p>熟练掌握各种元素，掌握元素特性,适当使用元素</p>
              <p>熟练掌握定位属性及区别，适当使用定位属性，熟练掌握锚点与精灵图使用</p>
              <p>浏览器内核，熟练解决浏览器兼容问题</p>
              <p>熟练掌握BFC作用，图片热点,表格常见属性</p>
            </div>
          </div>
          <div class="course_des_content" v-if="courseid == 2">
            <div class="course_des_content_left">
              <p>CSS3新特性</p>
              <p>CSS2d</p>
              <p>CSS3变形</p>
              <p>CSS3过渡</p>
              <p>CSS3动画</p>
              <p>Flex</p>
              <p>CSS3新增单位</p>
              <p>移动端开发</p>
              <p>圣杯布局，双飞翼布局</p>
            </div>
            <div class="course_des_content_right">
              <p>熟练掌握CSS3新选择器,新特性</p>
              <p>熟练掌握CSS3 2D转场属性</p>
              <p>熟练掌握CSS3 3D变形属性</p>
              <p>熟练掌握CSS3过渡属性,及使用</p>
              <p>熟练掌握CSS3动画属性,及使用</p>
              <p>熟练使用flex属性与布局</p>
              <p>熟练掌握CSS3新增单位</p>
              <p>熟练掌握移动端开发</p>
              <p>掌握各种布局方式的优势与不足使用场景，具体实现</p>
              <p>熟练使用Bootstrap布局，样式问题</p>
            </div>
          </div>
          <div class="course_des_content" v-if="courseid == 3">
            <div class="course_des_content_left">
              <p>jquery</p>
              <p>Node</p>
              <p>原生Ajax</p>
              <p>JSONP</p>
              <p>jquery ajax</p>
              <p>Express</p>
              <p>MongoDB</p>
              <p>API接口文档</p>
              <p>token, jwt</p>
              <p>webSocket</p>
              <p>shal,md5加密</p>
              <p>Echarts</p>
            </div>
            <div class="course_des_content_right">
              <p>熟练掌握js面向对象开发</p>
              <p>借助jQuery提升开发效率，同时借鉴和学习jQuery的封装实现思想</p>
              <p>巩固面向对象的相关知识，加深对面象对象的理解</p>
              <p>全面系统讲解Node.js中最核心的、必知必会的概念，能够基于模块化的开发思想优化项目代码编写出高效、健壮的代码，同时培养出Node.js的编程思维。</p>
              <p>系统的学习网页数据交互的Ajax技术，建立前后端交互的知识体系，为学生夯实网络编程的基础，为后期高级课程的学习做好铺垫。</p>
              <p>熟练解决跨域问题，熟练使用jquery ajax</p>
              <p>通过expess完成服务端开发</p>
              <p>熟练操作mongodb数据库</p>
            </div>
          </div>
          <div class="course_des_content" v-if="courseid == 4">
            <div class="course_des_content_left">
              <p>Less,Sass,Stylus</p>
              <p>ES6</p>
              <p>Webpack</p>
              <p>微信小程序</p>
              <p>Mpvue框架</p>
              <p>Vue，Vue-Router</p>
              <p>Vuex</p>
            </div>
            <div class="course_des_content_right">
              <p>熟练掌握 Less,Sass,Stylus</p>
              <p>深入理解ECMAScript的知识体系及工作机制</p>
              <p>
                熟悉ES6+新的语法特性
                注重抽象思维的培养，具备面向对象编程的的能力。
              </p>
              <p>学会webpack的配置,理解webpack的作用与原理,拥有工程化的前端思维,掌握前端工程化的常见工具及工作原理</p>
              <p>基于真实需求综合运用小程序开发的相关知识，突出小程序API的真实应用场景</p>
              <p>强化和巩固小程序开发的相关知识，提升综合解决问题的能力。</p>
              <p>熟练使用mpvue开发项目,熟悉Vue中常用API的使用,使用Vue语法对常见页面进行开发</p>
              <p>了解组件化的思想,了解路由的使用,知道ElementUI的基本使用，熟悉脚手架工具的使用，了解数据驱动视图的思想。</p>
            </div>
          </div>
          <div class="course_des_content" v-if="courseid == 5">
            <div class="course_des_content_left">
              <p>Typescript</p>
              <p>HBuilderX</p>
              <p>UniApp</p>
              <p>职业素养</p>
              <p>企业级项目实战</p>
            </div>
            <div class="course_des_content_right">
              <p>掌握Typescript基本编程方法</p>
              <p>理解类型系统理解并能使用泛型</p>
              <p>了解类型的推断过程</p>
              <p>了解类型的高级用法，能理解代码</p>
              <p>将Typescript编程成JavaScript可以运行在其他任何浏览器上,为开发大型应用做准备</p>
              <p>uni-app实现跨平台开发的原理机制，理清单文件组件与原生小程序组件的对应关系以及uni-app开发小程序时开发时与原生小程序的差异</p>
            </div>
          </div>
          <div class="course_des_content" v-if="courseid == 6">
            <div class="course_des_content_left">
              <p>Iinuxcentos7 新特性</p>
              <p>linux shell</p>
              <p>nginx</p>
              <p>Tomcat</p>
              <p>Docker</p>
              <p>python</p>
              <p>Django</p>
              <p>软件测试</p>
              <p>云服务器</p>
              <p>域名</p>
              <p>阿里云项目部署</p>
              <p>性能优化</p>
            </div>
            <div class="course_des_content_right">
              <p>熟练操作linux,centos系统</p>
              <p>熟练编写shell脚本</p>
              <p>熟练使用nginx项目部署</p>
              <p>熟练使用tomcat项目部署</p>
              <p>熟练使用docker容器</p>
              <p>掌握Python基础语法，具备基础的编程能力</p>
              <p>建立起编程思维以及面向对象程序设计思想</p>
              <p>掌握Python Web主流框架Django的使用</p>
              <p>可根据Web框架设计，开发对应的 数据库</p>
              <p>可根据业务流程图，开发Web网站的前后台业务</p>
            </div>
          </div>
        </div>
      </div>
      <div class="coursebg"></div>
    </div>

    <div class="calculationPage_title" id="3">
      <div class="div-img_box">
        <img class="div-img" src="../../assets/images/calculationPage/div.png" />
      </div>
      <div>
        <span>优秀的讲师，优秀的团队，铸就优秀的你</span>
      </div>
      <div class="hov-img_box">
        <img class="hov-img" src="../../assets/images/calculationPage/hov.png" />
      </div>
    </div>
    <div class="cal_line">
      <div class="color_block"></div>
    </div>
    <div class="teacher">
      <div class="teacher_box">
        <div class="teacher_left">
          <img
            v-if="teacherid == 1"
            class="teacher_img"
            src="../../assets/images/calculationPage/liudangwei.png"
          />
          <img
            v-if="teacherid == 2"
            class="teacher_img"
            src="../../assets/images/calculationPage/duannaihui.png"
          />

          <img
            v-if="teacherid == 3"
            class="teacher_img"
            src="../../assets/images/calculationPage/huxingjing.png"
          />
          <img
            v-if="teacherid == 4"
            class="teacher_img"
            src="../../assets/images/calculationPage/wangdong.png"
          />
        </div>
        <div class="teacher_right">
          <img class="teacher_name_img" src="../../assets/images/calculationPage/douhao.png" />
          <div class="teacher_name" v-if="teacherid == 1">刘党伟</div>
          <div class="teacher_name" v-if="teacherid == 2">段乃珲</div>

          <div class="teacher_name" v-if="teacherid == 3">胡兴晶</div>
          <div class="teacher_name" v-if="teacherid == 4">王东</div>

          <div class="teacher_title" v-if="teacherid == 1">前端开发方向主任</div>
          <div class="teacher_title" v-if="teacherid == 2">前端开发授课讲师</div>

          <div class="teacher_title" v-if="teacherid == 3">前端开发授课讲师</div>
          <div class="teacher_title" v-if="teacherid == 4">前端开发授课讲师</div>

          <div class="teacher_descrition" v-if="teacherid == 1">
            郑州大学 软件工程专业
            <br />历任：北京东软望海科技有限公司软件工程师、北京宜天信达科技有限公司项目总监、杭州医惠科技有限公司项目经理、北京天瑞地安科技有限公司教学主任。近十年的Java
            EE后台、WEB前端、Hybrid
            APP开发经验。扎实的前端技术功底，对WEB前端开发培训有深入的理解，具有全面把控课程大纲、提升教学的能力，教学经验丰富，授课风趣幽默，注重理论基础与实际项目相结合，善于对技术原理的讲解以及学生自学能力的培养。
          </div>
          <div class="teacher_descrition" v-if="teacherid == 2">
            毕业院校：电子科技大学
            <br />工作履历：6年软件开发经验和4年项目管理经验，历任高级软件工程师、项目经理、培训师等职务，具备多年互联网程序开发经验以及管理经验。精通JavaScript、jQuery、nodejs、vue、react等前端技术；对网站架构设计有浓厚的兴趣和深入的研究。
            <br />讲师语录：把简单的事情做彻底，把平凡的事情做经典，把每一件小事都做得更精彩。
          </div>

          <div class="teacher_descrition" v-if="teacherid == 3">
            毕业院校：东北农业大学
            <br />工作履历：6年大型软件开发和项目管理经验，4年授课经验。参与的项目名称：
            哈尔滨市OA后台管理系统； 地下水检测系统； 哈尔滨市铁塔管理系统；
            黑龙江省大数据扶贫项目；
            <br />讲师语录：混一天（0.99
            <sup>365</sup>=0.03）和努力一天（1.01
            <sup>365</sup>=37.8）的差距
            越努力越幸运
          </div>

          <div class="teacher_descrition" v-if="teacherid == 4">
            毕业院校：山西大学
            <br />工作履历：六年前端开发经验，后就职于中融民信、宜信等公司，历任前端负责人、研发经理等职位，曾主导社群电商、安全、电商等相关领域的项目，对小程序、Vue、Node等技术有深入的研发，擅长以项目、案例驱动教学，对待代码严谨，注重培养学生独立思考与解决问题的能力，大前端路上，一起同行。参与开发的项目有：中国银行创业在线项目、孔夫子电子商务平台建设、书店项目、拍卖项目等。
            <br />讲师语录：业精于勤而荒于嬉，行成于思而毁于随
          </div>
          <div class="teacher_list">
            <div
              :class="[
              'teacher_list_item',
              teacherid == '1' ? 'teacher_list_item_activity_span' : 'teacher_list_item_span',
            ]"
            >
            <img
                src="../../assets/images/calculationPage/teacher/liuDangWei_checked.png"
                @mouseenter="hoverteacher('1')"
                v-if="teacherid == '1' "
              />
              <img
                v-else
                src="../../assets/images/calculationPage/teacher/liuDangWei_unchecked.png"
                @mouseenter="hoverteacher('1')"
              />
              <span>刘党伟</span>
            </div>
            <div
              :class="[
              'teacher_list_item',
              teacherid == '2' ? 'teacher_list_item_activity_span' : 'teacher_list_item_span',
            ]"
            >
            <img
                src="../../assets/images/calculationPage/teacher/duanNaiHui_checked.png"
                @mouseenter="hoverteacher('2')"
                v-if="teacherid == '2' "
              />
              <img
                v-else
                src="../../assets/images/calculationPage/teacher/duanNaiHui_unchecked.png"
                @mouseenter="hoverteacher('2')"
              />
              <span>段乃珲</span>
            </div>

            <div
              :class="[
              'teacher_list_item',
              teacherid == '3' ? 'teacher_list_item_activity_span' : 'teacher_list_item_span',
            ]"
            >
            <img
                src="../../assets/images/calculationPage/teacher/huXingJing_checked.png"
                @mouseenter="hoverteacher('3')"
                v-if="teacherid == '3' "
              />
              <img
                v-else
                src="../../assets/images/calculationPage/teacher/huXingJing_unchecked.png"
                @mouseenter="hoverteacher('3')"
              />
              <span>胡兴晶</span>
            </div>
            <div
              :class="[
              'teacher_list_item',
              teacherid == '4' ? 'teacher_list_item_activity_span' : 'teacher_list_item_span',
            ]"
            >
              <img
                src="../../assets/images/calculationPage/teacher/wangDong_checked.png"
                @mouseenter="hoverteacher('4')"
                v-if="teacherid == '4' "
              />
              <img
                v-else
                src="../../assets/images/calculationPage/teacher/wangDong_unchecked.png"
                @mouseenter="hoverteacher('4')"
              />
              <span>王东</span>
            </div>
          </div>
        </div>
      </div>
      <img class="teacher_name_num" src="../../assets/images/calculationPage/num_list.png" />
      <div class="teacherbg"></div>
    </div>
    <div class="calculationPage_title" id="4">
      <div class="div-img_box">
        <img class="div-img" src="../../assets/images/calculationPage/div.png" />
      </div>
      <div>
        <span>多种就业渠道 帮助学员优质就业</span>
      </div>
      <div class="hov-img_box">
        <img class="hov-img" src="../../assets/images/calculationPage/hov.png" />
      </div>
    </div>
    <div class="cal_line">
      <div class="color_block"></div>
    </div>
    <div class="jobchannels">
      <div class="jobchannels_box">
        <div class="jobchannels_item" @mouseenter="hoverjob(1)">
          <img class="jobchannels_img" src="../../assets/images/calculationPage/jobchannels4.png" />
          <div
            :class="[
            'jobchannels_text',
            jobid == '1' ? 'jobchannels_text_activity' : '',
          ]"
          >
            <span class="greynum">一</span> 就业辅导
            <span class="greynum">一</span>
          </div>
        </div>
        <div
          class="jobchannels_item"
          style="margin-top: 40px; margin-left: -2px"
          @mouseenter="hoverjob(2)"
        >
          <img class="jobchannels_img" src="../../assets/images/calculationPage/jobchannels2.png" />
          <div
            :class="[
            'jobchannels_text',
            jobid == '2' ? 'jobchannels_text_activity' : '',
          ]"
          >
            <span class="greynum">一</span> 异地就业
            <span class="greynum">一</span>
          </div>
        </div>
        <div class="jobchannels_item" style="margin-left: -2px" @mouseenter="hoverjob(3)">
          <img class="jobchannels_img" src="../../assets/images/calculationPage/jobchannels3.png" />
          <div
            :class="[
            'jobchannels_text',
            jobid == '3' ? 'jobchannels_text_activity' : '',
          ]"
          >
            <span class="greynum">一</span> 名企内推
            <span class="greynum">一</span>
          </div>
        </div>
        <div
          class="jobchannels_item"
          style="margin-top: 40px; margin-left: -2px"
          @mouseenter="hoverjob(4)"
        >
          <img class="jobchannels_img" src="../../assets/images/calculationPage/jobchannels1.png" />
          <div
            :class="[
            'jobchannels_text',
            jobid == '4' ? 'jobchannels_text_activity' : '',
          ]"
          >
            <span class="greynum">一</span> 名企入校直招
            <span class="greynum">一</span>
          </div>
        </div>
      </div>

      <div class="jobchannelsbg"></div>
    </div>

    <div class="calculationPage_title" id="5">
      <div class="div-img_box">
        <img class="div-img" src="../../assets/images/calculationPage/div.png" />
      </div>
      <div>
        <span>积云教育“大学校园”学习环境</span>
      </div>
      <div class="hov-img_box">
        <img class="hov-img" src="../../assets/images/calculationPage/hov.png" />
      </div>
    </div>
    <div class="cal_line">
      <div class="color_block"></div>
    </div>
    <div class="school">
      <div class="school_box">
        <div class="school_img">
          <img class="school_long_img" src="../../assets/images/qianduan-hj/hj1.jpg" />
          <p>积云校区</p>
        </div>
        <div class="school_img">
          <img class="school_short_img" src="../../assets/images/qianduan-hj/hj2.jpg" />
          <p>教室</p>
        </div>
        <div class="school_img">
          <img class="school_short_img" src="../../assets/images/qianduan-hj/hj3.jpg" />
          <p>教室</p>
        </div>

        <div class="school_img">
          <img class="school_short_img" src="../../assets/images/calculationPage/school5.png" />
          <p>宿舍环境</p>
        </div>

        <div class="school_img">
          <img class="school_short_img" src="../../assets/images/qianduan-hj/hj4.jpg" />
          <p>操场</p>
        </div>
        <div class="school_img">
          <img class="school_long_img" src="../../assets/images/qianduan-hj/hj5.jpg" />
          <p>餐厅环境</p>
        </div>
        <!-- <huanjing></huanjing> -->
      </div>
      <div class="schoolbg"></div>
    </div>

    <div class="calculationPage_title">
      <div class="div-img_box">
        <img class="div-img" src="../../assets/images/calculationPage/div.png" />
      </div>
      <div>
        <span>积云教育 职教行业的创新领导品牌</span>
      </div>
      <div class="hov-img_box">
        <img class="hov-img" src="../../assets/images/calculationPage/hov.png" />
      </div>
    </div>
    <div class="cal_line">
      <div class="color_block"></div>
    </div>
    <div class="reward">
      <div class="rewardbox">
        <div class="reward_title">
          <img class="reward_title-img" src="../../assets/images/calculationPage/reward_title.png" />
        </div>
        <div class="reward_list">
          <img class="reward_list-img" src="../../assets/images/calculationPage/reward01.png" />
          <img class="reward_list-img" src="../../assets/images/calculationPage/reward02.png" />
          <img class="reward_list-img" src="../../assets/images/calculationPage/reward03.png" />
          <img class="reward_list-img" src="../../assets/images/calculationPage/reward04.png" />
          <img class="reward_list-img" src="../../assets/images/calculationPage/reward05.png" />
          <img class="reward_list-img" src="../../assets/images/calculationPage/reward06.png" />
          <img class="reward_list-img" src="../../assets/images/calculationPage/reward07.png" />
          <img class="reward_list-img" src="../../assets/images/calculationPage/reward08.png" />
          <img class="reward_list-img" src="../../assets/images/calculationPage/reward09.png" />
          <img class="reward_list-img" src="../../assets/images/calculationPage/reward10.png" />
          <img class="reward_list-img" src="../../assets/images/calculationPage/reward11.png" />
          <img class="reward_list-img" src="../../assets/images/calculationPage/reward12.png" />
        </div>
      </div>
      <div class="rewardbg"></div>
    </div>

    <div class="addressinfo">
      <div class="addressinfo_left" style="margin-right: 20px;">
        <div class="address_text">
          <div class="address_left">
            <p class="address_city">北京校区</p>
            <p class="addressinfo_text">北京市海淀区上庄路白水洼77号</p>
          </div>
          <div class="address_right">
            <p class="address_city">河南校区</p>
            <p class="addressinfo_text">郑州市新亚产业园</p>
            <p class="addressinfo_text">郑州市航空港区智能手机产业园5号地</p>
          </div>
          <div class="address_right">
            <p class="address_city">山西校区</p>
            <p class="addressinfo_text">山西省晋中市榆次区文华街燕金商务楼</p>
          </div>
        </div>
        <div class="address_text" style="margin-bottom: 30px;">
          <div class="address_left">
            <p class="address_city">积云教育总部</p>
            <p class="addressinfo_text">北京市昌平区TBD云集中心4号楼C座</p>
          </div>
        </div>
      </div>
      <div class="addressinfo_right">
        <div class="address_city" style="color: #fff;">更多精彩内容请关注</div>
        <div class="address_code">
          <div>
            <img class="address_code_img" src="../../assets/images/calculationPage/dingyuehao.png" />
            <p>服务号</p>
          </div>
          <div>
            <img class="address_code_img" src="../../assets/images/calculationPage/fuwuhao.png" />
            <p>订阅号</p>
          </div>
        </div>
      </div>
    </div>
    <div class="company">
      Copyright 2018 - 2023 Usian.cn 北京优思安科技有限公司  版权所有  京ICP备14057463号-1
    </div>
    <div class="bottomBox">
      <div class="bottomInput">
        <img
          style="width: 210px; height: auto; margin-right: 36px"
          src="../../assets/images/calculationPage/bottom-input-img.png"
        />
        <div class="bottominputItem">
          <img src="../../assets/images/calculationPage/bottom-input-icon2.png" />
          <input
            class="bottominputText"
            type="text"
            placeholder="如何称呼您?"
            v-model="inputBottomNameVal"
          />
        </div>
        <div class="bottominputItem">
          <img src="../../assets/images/calculationPage/bottom-input-icon.png" />
          <input
            class="bottominputText"
            type="text"
            placeholder="您的手机号?"
            v-model="inputBottomPhoneVal"
          />
        </div>
        <div class="bottom-btn" @click="handleClickbottom()"></div>
        <div
          style="
            transform: rotate(330deg);
            position: absolute;
            right: 130px;
            bottom: 10px;
          "
        >
          <img
            class="img-finger"
            style="width: 20px"
            src="../../assets/images/calculationPage/finger.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper.min.js";
import "swiper/css/swiper.min.css";
import huanjing from "../../components/huanjing/huanjing";
export default {
  metaInfo: {
    title: "前端开发培训|前端培训|HTML5培训-积云教育",
    meta: [
      {
        name: "description",
        content:
          "前端开发培训机构选择积云教育，积云教育前端开发课程包含了HTML5,CSS3,小程序开发，移动端开发等内容,拥有完整的web前端开发培训体系,学web前端开发推荐积云教育。",
      },
      {
        name: "keywords",
        content:
          "前端开发培训,前端培训,HTML5开发培训,前端开发培训机构,积云前端培训",
      },
    ],
  },
  data () {
    return {
      advantage_num: 0,
      courseid: 1,
      teacherid: 1,
      jobid: 1,
      inputNameVal: "",
      inputPhoneVal: "",
      inputBottomNameVal: "",
      inputBottomPhoneVal: "",
    };
  },
  components: {
    huanjing
  },
  created () { },
  mounted () {
    new Swiper(".top_slider", {
      autoplay: true,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });

    window.onscroll = function () {
      var btn = document.getElementById("banneritem");
      var lagoudomimg = document.getElementById("lagou");
      var zldomimg = document.getElementById("zl");
      var lydomimg = document.getElementById("ly");
      var bossdomimg = document.getElementById("boss");
      var bpdomimg = document.getElementById("bp");
      var scrollTop =
        document.body.scrollTop ||
        (document.documentElement && document.documentElement.scrollTop);
      if (scrollTop >= 100) {
        btn.style.display = "none";
      } else {
        btn.style.display = "flex";
      }

      if (scrollTop >= 910) {
        lagoudomimg.className = " lagou";
        zldomimg.className = " zl";
        lydomimg.className = " ly";
        bossdomimg.className = " boss";
        bpdomimg.className = " bp";
      }
    };
  },
  methods: {
    advice () {
      window.mantis.requestChat()
    },
    clickcategory (item, index) {
      this.categoryIndex = index;
    },
    hoverItem (id) {
      this.advantage_num = id;
    },
    hovercourse (id) {
      this.courseid = id;
    },
    hoverteacher (id) {
      this.teacherid = id;
    },
    hoverjob (id) {
      this.jobid = id;
    },
    handleClickFree () {
      var url = encodeURIComponent(window.location.href);

      if (localStorage.clickNum == 1) {
        alert("您已提交，请不要重复提交");
        return false;
      }

      window.sendPage(this.inputPhoneVal, this.inputNameVal, url)
    },
    handleClickbottom () {
      var url = encodeURIComponent(window.location.href);

      if (localStorage.clickNum == 1) {
        alert("您已提交，请不要重复提交");
        return false;
      }

      window.sendPage(this.inputBottomPhoneVal, this.inputBottomNameVal, url)
    },
  },
};
</script>

<style scoped>
@import url("../../assets/css/calculationPage.css");
</style>
